import './App.css';
import {functions} from "./firebase";
import SumsubWebSdk from '@sumsub/websdk-react'
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import {useEffect, useState} from "react";
const uuid = require("uuid")
function App() {

    const [executeCallable, loading, error] = useHttpsCallable(functions, "generateToken");

    const [token, setToken] = useState(null);


    const errorHandler = (e) => {
        console.log(e);
    }
    const onMessage = (e) => {
        console.log(e);
    }
    const expirationHandler = () => {
        console.log("expired");
    }

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        const id = params.id || localStorage.getItem("id") ||  uuid.v4();


        localStorage.setItem("id", id);



        executeCallable({externalId: id})
            .then(({data}) => {
                if (data?.token) {
                    setToken(data?.token);
                }
            })
    }, [])


    if (loading || !token) {
        return null;
    }

    if (error) {
        return <p>error from token generation</p>
    }


    return (
      <SumsubWebSdk
          accessToken={token}
          expirationHandler={expirationHandler}
          config={{lang: "en"}}
          options={{ addViewportTag: false, adaptIframeHeight: true}}
          onMessage={onMessage}
          onError={errorHandler}
      />
  );
}

export default App;
