// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCojRg1uSwICAXTS1uqUpOjP5zrjm4kgSk",
  authDomain: "sumsub-be2a6.firebaseapp.com",
  projectId: "sumsub-be2a6",
  storageBucket: "sumsub-be2a6.appspot.com",
  messagingSenderId: "795353888982",
  appId: "1:795353888982:web:693889c4bf63738957f177",
  measurementId: "G-JCEG6GCYNJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const functions = getFunctions(app);

